<!-- eslint-disable vue/require-default-prop -->
<template>
  <div>
    <b-sidebar
      id="edit-route-toggle"
      ref="modal"
      header-class="headerModali"
      centered
      right
      shadow
      :title="$t('EditRoute')"
      width="30%"
      hide-footer
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('EditRoute')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-form style="padding-left: 10px;padding-right: 20px;">
        <b-form-group
          id="input-group-1"
          :label="$t('RouteName')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.route.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <!-- :state="validateState('firstName')" -->
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="$t('Filiale')"
          label-for="input-2"
        >
          <vue-select
            v-model="store"
            required
            :options="getFiliales"
            label="storeName"
            :value="storeGLN"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.store.required"
            style="color: red"
          >Please select a filiale.</span>
          <!-- :state="validateState('gender')" -->
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('StartingDate')"
          label-for="input-9"
        >
          <date-picker
            v-model="route.startDate"
            type="datetime"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
          />
          <span
            v-show="formSubmitted && !$v.route.startDate.required"
            style="color: red"
          >{{ $t('SelectDate') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('EndingDate')"
          label-for="input-9"
        >
          <date-picker
            v-model="route.endDate"
            type="datetime"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
          />
          <span
            v-show="formSubmitted && !$v.route.endDate.required"
            style="color: red"
          >{{ $t('SelectDate') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Amount')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.route.amount.$model"
            :state="validateState('amount')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <div
        v-if="(isChanged || isChanged1 || getEditTourEditable.length > 0)"
        style="margin-top: 10px"
      >
        <div
          v-for="item in getEditTourEditable"
          :key="item.mainId"
          style="
          background-color: gainsboro;
          border-radius: 8px;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

        "
        >
          <div
            style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;"
          >
            <b-form-group
              v-if="!item.isTruck"
              id="input-group-8"
              label="Employee Name"
              label-for="input-25"
            >
              <!-- {{ item.tourName }} -->
              {{ item.employeeName }}
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="item.isTruck ? 'Route Name' : item.isOnTour ? 'Route Name' : 'Filiale Name'"
              label-for="input-25"
            >
              <!-- :label="item.isOnTour ? 'Route Name' : 'Filiale Name'" -->
              <!-- {{ item.tourName }} -->
              {{ item.isOnTour ? item.routeName : item.storeName }}
            </b-form-group>
            <!-- <b-form-group
              id="input-group-8"
              label="Ends in"
              label-for="input-25"
            >
              endshift
            </b-form-group> -->
            <b-button
              type="button"
              variant="none"
              style="margin-left: 10px;"
              class="buttonSubmit"
              @click="item.isTruck ? fetchTrucks() : item.isExchanged ? exEmployee(item.storeId) : employee(item.storeId) ,replaceShow(), selectedItemId = item.mainId"
            >
              <!-- getRouteID(item.tourId), -->
              <!-- fetchTrucks(item.storeGLN, item.endShift), -->
              Replace
            </b-button>
          </div>
          <p style="font-weight: bold;">
            {{ item.isExchanged ? `${item.employeeName} is exchanged in ${item.storeName} filiale` : `Truck is in ${item.routeName} route` }}
          </p>
          <!-- v-if="replace == true && item.tourId === selectedItemId" -->
          <b-form-group
            v-if="replace == true && item.mainId === selectedItemId"
            style="width: 100%"
            :label="item.isTruck ? 'Select free Truck' : 'Select free Employee'"
            label-for="input-25"
          >
            <vue-select
              v-if="!item.isTruck"
              id="input-8"
              v-model="selectedEmployee"
              :reduce="(e) => e.userId"
              :options="getfreeEmployees"
              placeholder="Select"
              label="userFullName"
            />
            <vue-select
              v-if="item.isTruck"
              id="input-8"
              v-model="truckSelected"
              :options="getFreeTrucks"
              :value="truckId"
              placeholder="Select"
              label="registrationPlate"
            />
            <b-button
              style="margin-top: 10px"
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="item.isTruck ? deactivateTruck(item.employeeUserId) : replaceEmployee(item.mainId)"
            >
              Exchange
            </b-button>
          </b-form-group>

        </div>
      </div>
      <pre />
      <div style="width: 100%; padding-left: 10px;">
        <b-button
          id="butoni"
          :disabled="getEditTourEditable.length !== 0"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitEditRoute"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

import 'vue2-datepicker/index.css';
import {
  required,
  minLength,
  numeric,
} from 'vuelidate/lib/validators';

export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editRoute: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    name: {
      type: String,
    },
  },
  data() {
    return {
      isChecked: false,
      showError: false,
      startTour: '',
      time1: null,
      formSubmitted: false,
      endTime: '',
      store: null,
      replace: false,
      storeGLN: null,
      isChanged: false,
      isFirstChange: true,
      isChanged1: false,
      selectedEmployee: null,
      truckSelected: null,
      selectedItemId: null,
      isFirstChange1: true,
      startTime: '',
      route: {
        tourId: '',
        name: '',
        storeGLN: '',
        startDate: '',
        endDate: '',
        amount: '',
      },
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      configEnd: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },

    };
  },
  validations: {
    route: {
      name: {
        required,
        minLength: minLength(3),
      },
      amount: {
        required,
        minLength: minLength(2),
        numeric,
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
    },
    store: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getFiliales', 'getEditTourEditable', 'getfreeEmployees', 'getFreeTrucks',
    ]),
    isValidDate() {
      if (!this.route.endDate || !this.route.startDate) {
        return true; // allow empty dates
      }
      const start = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').toDate();
      const end = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').toDate();
      return start <= end;
    },
  },
  watch: {
    'store.storeGLN': {
      handler(value) {
        this.route.storeGLN = value;
      },
      immediate: true,
    },
    name(value) {
      this.store = value
    },
    'route.startDate': {
      handler(value) {
        const time1 = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const time2 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        this.tour_editable({
          currentTourId: this.editRoute.tourId,
          newStartShift: time1,
          newEndShift: time2,
        });

        if (this.isFirstChange) {
          this.isFirstChange = false;
        } else {
          this.isChanged = true;
        }
      },
    },
    'route.endDate': {
      handler(value) {
        const time1 = moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        const time2 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        this.tour_editable({
          currentTourId: this.editRoute.tourId,
          newStartShift: time2,
          newEndShift: time1,
        });

        if (this.isFirstChange1) {
          this.isFirstChange1 = false;
        } else {
          this.isChanged1 = true;
        }
      },
    },
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.route.endDate = null;
      }
    },
  },
  mounted() {
    this.route = this.editRoute
    this.store = this.name
  },
  created() {
    this.route.startDate = this.editRoute.startDate
    this.route.endDate = this.editRoute.endDate
  },
  methods: {
    ...mapActions(['exchangeTruckInATour', 'getTourNameByEmployeeUserIdStartAndEndShift', 'tour_editable', 'freeEmployee', 'releaseAndReplaceEmployeeFromTour', 'loadFreeTrucks']),
    setTime(value) {
      if (value == true) {
        this.endTime = '17:00';
        this.startTime = '8:00';
      } else {
        this.endTime = '';
        this.startTime = '';
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.route[name];
      return $dirty ? !$error : null;
    },
    exEmployee(value) {
      const time1 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const time2 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.freeEmployee({
        storeId: value,
        startDate: time1,
        endDate: time2,
        // includeTeamLeaders: true,
        // includeManager: false,
      }).then((res) => {
        console.log(res);
      });
    },
    employee(value) {
      const time1 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const time2 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.freeEmployee({
        storeId: value,
        startDate: time1,
        endDate: time2,
        // includeTeamLeaders: true,
        // includeManager: false,
      }).then((res) => {
        console.log(res);
      });
    },
    fetchTrucks() {
      const time1 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const time2 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.loadFreeTrucks({
        storeGLN: this.editRoute.storeGLN,
        startShift: time1,
        endShift: time2,
      }).then((res) => {
        console.log(res);
      });
    },
    async replaceEmployee(value) {
      await this.releaseAndReplaceEmployeeFromTour(
        {
          obj: {
            truckStaffId: value,
            // tourTruckId: this.selectedItemId,
            employeePlanId: null,
            storeId: null,
            substituteEmployee: this.selectedEmployee,
            message: '',
            dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          },
          successCallback: () => {
            const time1 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            const time2 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            this.tour_editable({
              currentTourId: this.editRoute.tourId,
              newStartShift: time2,
              newEndShift: time1,
            });
            this.substituteEmployee = null;
            this.substituteTeamLeader = null;
          },
        },
      );
    },

    deactivateTruck(value) {
      this.exchangeTruckInATour({
        tourId: this.editRoute.tourId,
        truckId: value,
        newTruckId: this.truckSelected.truckId,
        successCallback: () => {
          const time1 = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
          const time2 = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
          this.tour_editable({
            currentTourId: this.editRoute.tourId,
            newStartShift: time2,
            newEndShift: time1,
          });
        },
      })
    },

    onSubmitEditRoute() {
      this.formSubmitted = true;
      this.$v.route.$touch();
      if (this.$v.route.$anyError) {
        return;
      }
      this.$v.store.$touch();
      if (this.$v.store.$anyError) {
        return;
      }
      // const startDateTime = moment(this.route.startDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      // this.route.startDate = startDateTime;
      const res = moment.tz.guess();
      const value2 = moment(this.route.startDate, 'DD-MM-yyyyTHH:mm:ss');
      this.route.startDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ'); // Note the [Z] added to format

      // const endDateTime = moment(this.route.endDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      // this.route.endDate = endDateTime;

      const res1 = moment.tz.guess();
      const value3 = moment(this.route.endDate, 'DD-MM-yyyyTHH:mm:ss');
      this.route.endDate = moment.tz(value3, 'DD-MM-yyyyTHH:mm:ss', res1).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      this.$emit('edit', this.route);
      this.$refs.modal.hide();
      this.onReset();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
      }, 0);
      this.$nextTick(() => {
      });
      this.onReset();
    },
    replaceShow(itemId) {
      this.replace = !this.replace;
      this.selectedItemId = itemId;
    },
    onReset() {
      if (this.name == null) {
        this.store = ''
      } else {
        (
          this.store = this.name
        )
      }
      // Reset our form values
      this.route.endTour = '';
      // this.route.endTour = '';
      this.isChanged = false
      this.route.startTour = '';
      // this.route.startTour = '';
      this.route.name = '';
      this.route.storeId = '';
      // this.route.storeId = '';
      // this.store = null;
      this.startTime = '';
      this.endTime = '';
      this.isChecked = false;
      this.formSubmitted = false;
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
.mx-datepicker {
    position: relative;
    display: inline-block;
    width: 100%;
}
</style>
