<template>
  <div>
    <b-modal
      id="add-route"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddNewRoute')"
      size="l"
      :width="'90%'"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('Filiale')"
          label-for="input-2"
        >
          <b-form-select
            v-model="store"
            required
            :options="selectFiliale"
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            label="storeName"
            :value="storeGLN"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <span
            v-show="formSubmitted && !$v.store.required"
            style="color: red"
          >Please select a store.</span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('RouteName')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-checkbox
          id="checkbox-2"
          v-model="isCheckedToday"
          name="checkbox-2"
          :value="true"
          :unchecked-value="false"
          @input="setToday(isCheckedToday)"
        >
          {{ $t('Today') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-1"
          v-model="isChecked"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
          @input="setDefaultTime(isChecked)"
        >
          {{ $t('AllDay') }}
        </b-form-checkbox>
        <b-form-group
          id="input-group-9"
          :label="$t('StartingDate')"
          label-for="input-9"
          style="width: 100%;"
        >
          <date-picker
            v-model="time1"
            type="datetime"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time1.required"
            style="color: red"
          >{{ $t('SelectDate') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('EndingDate')"
          label-for="input-9"
          style="width: 100%;"
        >
          <date-picker
            v-model="time2"
            type="datetime"
            value-type="format"
            :placeholder="$t('Select')"
            :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
            :lang="lang"
          />
          <span
            v-show="formSubmitted && !$v.time2.required"
            style="color: red"
          >{{ $t('SelectDate') }}</span>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('Amount')"
          label-for="input-1"
        >
          <b-form-input
            id="input-2"
            v-model="$v.form.amount.$model"
            placeholder="2000CHF"
            :state="validateState('amount')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <pre />
        <div style="width: 110%">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddRoute"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'flatpickr/dist/flatpickr.css';
import { validationMixin } from 'vuelidate';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  required,
  minLength,
  numeric,
} from 'vuelidate/lib/validators';


export default {
  components: {
    DatePicker,
  },
  mixins: [validationMixin],
  data() {
    return {
      isChecked: false,
      isCheckedToday: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      firstDay: 2,
      time1: null,
      time2: null,
      time3: null,
      formattedDate: null,
      formSubmitted: false,
      showError: false,
      startTour: '',
      endTour: '',
      check: false,
      endTime: '',
      store: null,
      storeGLN: null,
      startTime: '',
      date: null,
      dateTime: '',
      config: {},
      configStart: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      configEnd: {
        altInput: true,
        altFormat: 'd-m-Y',
        minDate: new Date(),
        dateFormat: 'Y-m-d',
      },
      form: {
        storeGLN: null,
        amount: 0,
        name: '',
        startTour: null,
        endTour: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      amount: {
        required,
        minLength: minLength(2),
        numeric,
      },
      startTour: {
        required,
      },
      endTour: {
        required,
      },
    },
    store: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getFiliales']),
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
      const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
      return start <= end;
    },
    selectFiliale() {
      const filiales = this.getFiliales
        .filter((option) => { return option.storeGLN && option.storeName })
        .map((option) => {
          return {
            value: option.storeGLN,
            text: option.storeName,
          }
        });
      filiales.unshift({ value: null, text: this.$t('Select'), disabled: true });
      return filiales;
    },
  },
  watch: {
    store: {
      handler(value) {
        this.form.storeGLN = value;
      },
      immediate: true,
    },
    time1(value) {
      // console.log('value', value)
      const res = moment.tz.guess();
      const value2 = moment(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.startTour = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('DD-MM-yyyyTHH:mm:ss.SSSZ'); // Note the [Z] added to format
      // console.log('dd', this.form.startTour)
      this.form.startTour = this.form.startTour.replace(/\+/, '%2B');
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment(value, 'DD-MM-yyyyTHH:mm:ss');
      this.form.endTour = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('DD-MM-yyyyTHH:mm:ss.SSSZ'); // Note the [Z] added to format
      // console.log('tre', this.form.endTour)
      this.form.endTour = this.form.endTour.replace(/\+/, '%2B');
      // console.log('tre123', this.form.endTour)
    },
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.store[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddRoute() {
      this.formSubmitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$v.store.$touch();
      if (this.$v.store.$anyError) {
        return;
      }
      this.$emit('click', this.form);
      this.$refs.modal.hide();
      this.onReset();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    setDefaultTime(value) {
      // const res = moment.tz.guess();

      if (value == true) {
        this.time1 = `${moment(this.time1, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment(this.time1, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')} 17:00`;
        // console.log('wer', this.time1)
        this.form.startTour = `${moment(this.time1, 'DD-MM-YYYY HH:mm').format('DD-MM-yyyyTHH:mm:ss.SSSZ')}`;
        this.form.startTour = this.form.startTour.replace(/\+/, '%2B');
        this.form.endTour = `${moment(this.time2, 'DD-MM-YYYY HH:mm').format('DD-MM-yyyyTHH:mm:ss.SSSZ')}`;
        this.form.endTour = this.form.endTour.replace(/\+/, '%2B');
      } else {
        this.form.startTour = '';
        this.form.endTour = '';
      }
    }, // Set the default value using moment.js library
    setToday(value) {
      if (value == true) {
        this.time1 = `${moment().format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment().format('DD-MM-YYYY')} 17:00`;
        this.form.startTour = `${moment(this.time1, 'DD-MM-YYYY HH:mm').format('DD-MM-yyyyTHH:mm:ss.SSSZ')}`;
        this.form.startTour = this.form.startTour.replace(/\+/, '%2B');
        this.form.endTour = `${moment(this.time2, 'DD-MM-YYYY HH:mm').format('DD-MM-yyyyTHH:mm:ss.SSSZ')}`;
        this.form.endTour = this.form.endTour.replace(/\+/, '%2B');
      } else {
        this.form.startTour = '';
        this.form.endTour = '';
      }
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.store = '';
      this.form.name = '';
      this.form.amount = '';
      this.time1 = '';
      this.form.startTour = '';
      this.form.endTour = '';
      this.time2 = '';
      this.isChecked = false
      this.isCheckedToday = false;
      this.formSubmitted = false;
    },
    dateModified(xdate) {
      return moment(xdate).tz('Europe').format('DD/MM/YYYY - HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
