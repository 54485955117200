<template>
  <div>
    <b-modal
      id="excel-route"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddRoutes')"
      size="lg"
      :width="'90%'"
      hide-footer
      @close="onCancel"
    >
      <div style="max-height: 400px; overflow: auto;">
        <div class="mb-3">
          <input
            id="formFile"
            class="form-control"
            accept=".csv"
            type="file"
            @change="handleFileUpload( $event )"
          >
        </div>
        <table
          v-if="parsed"
        >
          <thead>
            <tr>
              <th
                v-for="(header, key) in content.meta.fields"
                :key="'header-'+key"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, rowKey) in content.data"
              :key="'row-'+rowKey"
            >
              <td
                v-for="(column, columnKey) in content.meta.fields"
                :key="'row-'+rowKey+'-column-'+columnKey"
                :class="{'red-cell': column === 'name' && !content.data[rowKey][column]}"
              >
                <input
                  v-model="content.data[rowKey][column]"
                  :disabled="columnKey === 0"
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-button
        type="button"
        variant="none"
        class="buttonSubmit"
        style="margin-top: 10px;"
        @click="submitUpdates"
      >
        {{ $t("Submit") }}
      </b-button>
      <b-button
        type="button"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 10px;"
        @click="onCancel"
      >
        {{ $t("Cancel") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Papa from 'papaparse';
// import axios from 'axios';
import { mapActions } from 'vuex';
import moment from 'moment';



export default {
  data() {
    return {
      file: '',
      content: [],
      parsed: false,
      pageSize: 15,
      pageNumber: 1,
    };
  },
  computed: {
    hasNullData() {
      return this.content.data.some((row) => { return !row.name });
    },
  },
  methods: {
    ...mapActions(['loadRouteByPagination', 'add_Tours_Excel']),
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        // eslint-disable-next-line func-names
        complete: function (results) {
          results.data.forEach((row) => {
            // eslint-disable-next-line no-restricted-syntax, guard-for-in
            for (const key in row) {
              if (key === 'Delivery costs amount' || key === 'Gesamtkosten der Lieferung' || key === 'Total frais de livraison') {
                if (row[key] === '') {
                  row[key] = 0;
                }
              }
              if (key === 'Round' || key === 'Runde' || key === 'Tournée') {
                if (row[key] === '') {
                  row[key] = '0';
                }
              }
            }
          })
          this.content = results;
          this.parsed = true;
        }.bind(this),
      });
    },
    async submitUpdates() {
      try {
        const updatedData = this.content.data.map((row) => {
          // const res = moment.tz.guess();
          // const value2 = moment.utc(row.Date, 'DD/MM/YYYY');
          // const formattedDate = moment.tz(value2, 'DD/MM/YYYY', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

          // const res1 = moment.tz.guess();
          // const value22 = moment.utc(row.Datum, 'DD/MM/YYYY');
          // const formattedDatee = moment.tz(value22, 'DD/MM/YYYY', res1).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
          const res = moment.tz.guess();
          let value = moment(row.Date, 'DD/MM/YYYY').startOf('day');
          if (!value.isValid()) {
            value = moment(row.Datum, 'DD/MM/YYYY');
          }
          const formattedDate = moment.tz(value, 'DD/MM/YYYY', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

          return {
            storeGLN: row.Hub || row.Depot || row.Entrepôt,
            startTour: formattedDate,
            name: row.Round || row.Runde || row.Tournée,
            amount: row['Delivery costs amount'] || row['Gesamtkosten der Lieferung'] || row['Total frais de livraison'],
          };
        });
        this.add_Tours_Excel({
          obj: updatedData,
          successCallback: () => {
            this.loadRouteByPagination({
              storeGLN: null,
              startShift: moment().format('DD-MM-YYYY'),
              endShift: null,
              tourName: null,
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
            });
            this.onReset();
            this.$refs.modal.hide();
            Swal.fire({
              icon: 'success',
              title: 'Routes added successfully',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2500,
            });
          },
        })
      } catch (error) {
        console.log('FAILURE!!', error);
        Swal.fire({
          icon: 'error',
          title: 'An error occurred while adding routes.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    onReset() {
      this.content = '';
      this.file = '';
      this.parsed = false
    },
  },
  dateModified(xdate) {
    return moment(xdate).format('YYYY-MM-DD');
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}

td[data-column-name='name']:empty  {
  background-color: red;
}
.red-cell {
  background-color: red;
}

.modal-content{
  width: 70% !important;
}
#excel-route___BV_modal_content_{
  width: 70% !important;
}
input{
  width: 100%;
}
th{
  width: 10%;
}
</style>
