<template>
  <div>
    <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
      {{ $t("Routes") }}
    </p>
    <div style="display: flex; flex-direction: row; justify-content: space-between; max-height: 76px;">
      <div style="display: flex; flex-direction: row">
        <button
          v-b-modal.add-route
          class="button buttonn"
          style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:220px"
          @click="loadFiliale"
        >
          <b-icon-plus />
          {{ $t('AddRoute') }}
        </button>
        <button
          v-b-modal.excel-route
          class="button"
          style="border-radius: 6px; height: 36px; margin-top: 25px; margin-left: 0px; width:220px"
        >
          <b-icon-plus />
          {{ $t('UploadCSV') }}
        </button>
      </div>
      <div
        style="display: flex; flex-direction: row;"
      >
        <b-form-group
          id="input-group-9"
          :label="$t('Filiale')"
          label-for="input-9"
          style="margin-right: 10px; width: 200px;margin-top: 5px"
        >
          <b-form-select
            ref="storeSelect"
            v-model="store"
            :clearable="false"
            required
            style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
            :options="selectFiliale"
            label="text"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <!-- @clear="clearSelection" -->
          <!-- @search="onSearchM" -->
          <!-- <li
            slot="list-footer"
            class="pagination"
            style="display: flex; width: 100%; justify-content: center; -ms-word-break: break-all; word-break: break-all; word-break: break-word;"
          >
            <button
              type="button"
              :style="pageC == 1 ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
              :disabled="pageC == 1"
              @click="prevColors"
            >
              {{ $t('Prev') }}
            </button>
            <button
              type="button"
              :style="pageC == Math.ceil(getTotalItemsForFiliales / 10) ? 'width: 50%; border: none;' : 'width: 50%; border: none; background-color: #242F6E; color: white'"
              :disabled="pageC == Math.ceil(getTotalItemsForFiliales / 10)"
              @click="nextColors"
            >
              {{ $t('Next') }}
            </button>
          </li> -->
        </b-form-group>
        <b-form-group
          id="input-group-9"
          :label="$t('FilterRouteByDate')"
          label-for="input-9"
          style="margin-bottom: 10px"
        >
          <div class="year">
            <button @click="previousDay">
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-control"
              format="DD-MM-YYYY"
              type="date"
              placeholder="Select Date"
              style="width: 193px; border: none;"
              :lang="lang"
              :clearable="false"
            />
            <button
              style="margin-left: 5px"
              @click="nextDay"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>
      </div>
    </div>

    <div style="width: 100%; padding-top: 16px; margin-top: -3px">
      <table
        class="team_table"
        style="width: 100% !important; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); text-align: left;"
      >
        <thead>
          <tr>
            <th style="padding: 10px; border-radius: 10px 0px 0px 0px">
              {{ $t("No.") }}
            </th>
            <th style="padding: 10px; border-radius: 0px 0px 0px 0px">
              {{ $t("RouteName") }}
            </th>
            <th style="padding: 10px">
              {{ $t('Filiale') }}
            </th>
            <th style="padding: 10px">
              {{ $t('StartingShift') }}
            </th>
            <th style="padding: 10px">
              {{ $t('EndingShift') }}
            </th>
            <th style="padding: 10px">
              {{ $t('Amount') }}
            </th>
            <!-- <th /> -->
            <th style="padding: 10px; border-radius: 0px 10px 0px 0px">
              {{ $t('Edit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(name,index) in getRoutesWithPagination"
            :key="name.tourId"
            style="border-bottom: 1px solid #dcdfe6; color: #606266;"
            @click="matProps(name)"
          >
            <td style="padding: 10px">
              {{ getTotalItemsForRoutes - (pageNumber - 1) * pageSize - index }}.
            </td>
            <td style="padding: 10px">
              {{ name.tourName }}
            </td>
            <td style="padding: 10px">
              {{ name.storeName }}
            </td>
            <td style="padding: 10px">
              {{ dateModified2(name.startShift) }}
            </td>
            <td style="padding: 10px">
              {{ dateModified2(name.endShift) }}
            </td>
            <!-- <td /> -->
            <td style="padding: 10px">
              {{ name.amount }}CHF
            </td>
            <td
              v-b-toggle.edit-route-toggle
              style="padding: 10px; text-align: left; cursor: pointer"
              @click="edit(name); loadFiliale(); storeNameForEdit(name.storeName)"
            >
              <b-icon-pencil />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-model="pageNumber"
        :total-rows="getTotalItemsForRoutes"
        :per-page="pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span>{{ $t("Previous") }}</span>
        </template>
        <template #next-text>
          <span>{{ $t("Next") }}</span>
        </template>
      </b-pagination>
    </div>
    <AddRouteModal @click="addFormRoute" />
    <EditRouteModal
      :name="editFilialeName"
      :edit-route="route"
      @edit="editRoutee"
    />
    <ExcelModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import AddRouteModal from '@/components/routes/modals/AddRouteModal.vue';
import EditRouteModal from '@/components/routes/modals/EditRouteModal.vue';
import ExcelModal from '@/components/routes/modals/ExcelModal.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone'

export default {
  components: {
    AddRouteModal,
    DatePicker,
    EditRouteModal,
    ExcelModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      initialId: '',
      pageSize: 15,
      pageNumber: 1,
      // storeGLN: null,
      requestData: null,
      status: true,
      editFilialeName: '',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      store: 'ALL',
      route: {
        tourId: '',
        name: '',
        storeGLN: '',
        startDate: '',
        endDate: '',
        amount: '',
      },
      pageP: 1,
      pageC: 1,
      classNameCh: '',
      selectedDate: moment().format('DD-MM-YYYY'),
      // selectedDate: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      color: {
        mainDressCategoryColorId: '',
        name: '',
      },
      getFiliale: {
        filialeName: 'BE-ZU',
        address: 'Bern',
        todayRoutes: '17/12/22',
        trucks: '18/12/22',
        totalEmployee: '08:00 AM - 4:00 PM',
      },
      isInitialRender: true,

    };
  },

  computed: {
    ...mapGetters(['getRoutesWithPagination', 'getRoutesWithPagination', 'getTotalItemsForRoutes', 'getFiliales', 'getTotalItemsForFiliales', 'getFilialesForRoutes']),
    selectFiliale() {
      const filiales = this.getFilialesForRoutes
        .filter((option) => { return option.storeGLN && option.storeName }) // Filter out objects without value or text
        .map((option) => {
          return {
            value: option.storeGLN,
            text: option.storeName,
          }
        });

      filiales.unshift({ value: 'ALL', text: 'ALL' });

      return filiales;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    pageNumber(value) {
      if (!this.isInitialRender) {
        this.loadRouteByPagination({
          storeGLN: null,
          startShift: this.selectedDate,
          endShift: null,
          tourName: null,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      } else {
        this.isInitialRender = false;
      }
    },
    selectedDate: {
      handler(value) {
        this.requestData = {
          ...this.requestData,
          storeGLN: this.store == 'ALL' ? null : this.store,
          startShift: value,
        };
        if (!this.isInitialRender) {
          this.makeRequest();
        } else {
          this.isInitialRender = false;
        }
      },
      immediate: true,
    },
    store: {
      handler(value) {
        console.log('store', value)
        if (value == 'ALL') {
          this.requestData = {
            ...this.requestData,
            storeGLN: null,
            startShift: this.selectedDate,
          };
        } else {
          this.requestData = {
            ...this.requestData,
            storeGLN: value,
            startShift: this.selectedDate,
          };
        }
        if (!this.isInitialRender) {
          this.makeRequest();
        } else {
          this.isInitialRender = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.isInitialRender) {
      this.fetchRoutes();
    } else {
      this.isInitialRender = false;
    }
    this.fetchFilialeForTrucks();
  },
  methods: {
    ...mapActions([
      'addTours',
      'loadFilialePagination',
      'loadRouteByPagination',
      'loadFilialePaginationForRoutes',
      'changeLoadingtoTrue',
      'clearRouteCache',
      'editRoute',
    ]),
    makeRequest() {
      setTimeout(() => {
        this.loadRouteByPagination({
          storeGLN: this.requestData.storeGLN,
          startShift: this.requestData.startShift,
          endShift: null,
          tourName: null,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
        this.requestData = null;
      }, 500);
    },
    async fetchRoutes() {
      try {
        await this.loadRouteByPagination({
          storeGLN: null,
          startShift: this.selectedDate,
          endShift: null,
          tourName: null,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }).then(() => {
        });
      } catch (e) {
        console.log(e);
      }
    },
    async fetchFilialeForTrucks() {
      try {
        //       // this.changeLoadingtoTrue(false);


        await this.loadFilialePaginationForRoutes({
          status: true,
          pageSize: 100,
          pageNumber: this.pageNumber,
        })
      } catch (e) {
        // console.log(e);
        //       // this.changeLoadingtoTrue(false);

      }
    },
    storeNameForEdit(value) {
      this.editFilialeName = value
    },
    edit(item) {
      this.route.tourId = item.tourId
      this.route.name = item.tourName
      this.route.storeGLN = item.storeGLN
      // this.route.startDate = this.dateModified3(item.startShift)
      // this.route.endDate = this.dateModified3(item.endShift)
      const originalTime = moment(item.startShift);
      this.route.startDate = originalTime.format('DD-MM-YYYY HH:mm')
      const originalTime2 = moment(item.endShift);
      this.route.endDate = originalTime2.format('DD-MM-YYYY HH:mm')
      this.route.amount = item.amount
    },
    async editRoutee(obj) {
      // console.log('ww', obj)
      await this.editRoute({
        object: obj,
        successCallback: () => {
          this.loadRouteByPagination({
            storeGLN: this.store.storeGLN,
            startShift: this.selectedDate,
            endShift: null,
            tourName: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      })
    },
    async addFormRoute(obj) {
      await this.addTours({
        name: obj.name,
        startTour: obj.startTour,
        amount: obj.amount,
        endTour: obj.endTour,
        storeGLN: obj.storeGLN,
        successCallback: async () => {
          this.clearRouteCache()
          await this.loadRouteByPagination({
            storeGLN: null,
            startShift: this.selectedDate,
            endShift: null,
            tourName: null,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          })
        },
      });
    },
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'DD-MM-YYYY').add(0.5, 'days').format('DD-MM-YYYY');
    },
    matProps(color) {
      this.$emit('click', color);
      this.classNameCh = color.colorName;
    },
    async loadFiliale() {
      await this.loadFilialePagination({
        status: true,
        pageNumber: 1,
        pageSize: 50,
      });
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY HH:mm');
    },
    dateModified2(xdate) {
      // const offset = moment.tz(moment.tz.guess()).utcOffset() / 60; // Calculate offset in hours
      const originalTime = moment(xdate);
      return originalTime.format('DD-MM-YYYY HH:mm');
    },

    dateModified3(xdate) {
      return moment.utc(xdate).format('DD-MM-YYYY HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">

.search-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
